import React from "react"
import Layout from "components/layout"
import usePageData from "hooks/queryTestimonialsPage"
import ViewTestimonials from "components/ViewTestimonials";

const TestimonialsPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewTestimonials pageData={pageData} />
    </Layout>
  )
}

export default TestimonialsPage
